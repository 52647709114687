import { useQueries, useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'
import {
    Address,
    LookupUser,
    queryClient,
    staleTime24Hours,
    useConnectivity,
    useOfflineStore,
    useSpaceMembers,
    useTownsContext,
    useUserLookupContext,
} from 'use-towns-client'
import { AccountAbstractionConfig, UserOps } from '@towns/userops'
import { useEnvironment } from './useEnvironmnet'

const queryKey = 'smartAccountAddress'

function querySetup({
    rootKeyAddress,
    userOpsInstance,
    cachedAddress,
    setOfflineWalletAddress,
    accountAbstractionConfig,
}: {
    rootKeyAddress: Address | undefined
    userOpsInstance: UserOps | undefined
    cachedAddress: Address | undefined
    setOfflineWalletAddress: (userId: string, abstractAccountAddress: string) => void
    accountAbstractionConfig: AccountAbstractionConfig | undefined
}) {
    const isAccountAbstractionEnabled = accountAbstractionConfig !== undefined
    return {
        queryKey: [queryKey, { isAccountAbstractionEnabled, rootKeyAddress }],
        queryFn: async () => {
            // if account abstraction is not enabled, we're using the root key address
            if (!isAccountAbstractionEnabled) {
                return rootKeyAddress
            }

            if (!rootKeyAddress) {
                return
            }

            if (cachedAddress) {
                return cachedAddress
            }

            if (!userOpsInstance) {
                return
            }
            const returnVal = await userOpsInstance.getAbstractAccountAddress({
                rootKeyAddress,
            })
            if (returnVal) {
                // console.log('setting offline wallet address', rootKeyAddress, returnVal)
                setOfflineWalletAddress(rootKeyAddress, returnVal)
            }
            return returnVal
        },
        enabled: !!rootKeyAddress,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        staleTime: staleTime24Hours,
    }
}

export function useAbstractAccountAddress({
    rootKeyAddress,
}: {
    rootKeyAddress: Address | undefined
}) {
    const { accountAbstractionConfig } = useEnvironment()
    const userOpsInstance = useUserOps()
    const setOfflineWalletAddress = useOfflineStore((s) => s.setOfflineWalletAddress)

    const cachedAddress = useCachedAddress(rootKeyAddress)
    return useQuery({
        ...querySetup({
            rootKeyAddress,
            userOpsInstance,
            accountAbstractionConfig,
            cachedAddress,
            setOfflineWalletAddress,
        }),
    })
}

export function useGetAbstractAccountAddressAsync() {
    const { accountAbstractionConfig } = useEnvironment()
    const userOpsInstance = useUserOps()
    const setOfflineWalletAddress = useOfflineStore((s) => s.setOfflineWalletAddress)
    const { loggedInWalletAddress } = useConnectivity()

    return useCallback(
        ({ rootKeyAddress }: { rootKeyAddress: Address | undefined }) => {
            const cachedAddress = getCachedAddress({
                rootKeyAddress,
                loggedInWalletAddress,
            })
            const qs = querySetup({
                rootKeyAddress,
                userOpsInstance,
                accountAbstractionConfig,
                cachedAddress,
                setOfflineWalletAddress,
            })
            return queryClient.fetchQuery({
                queryKey: qs.queryKey,
                queryFn: qs.queryFn,
            })
        },
        [loggedInWalletAddress, userOpsInstance, accountAbstractionConfig, setOfflineWalletAddress],
    )
}

export type LookupUserWithAbstractAccountAddress = LookupUser & {
    abstractAccountAddress: Address
}
// TODO: we should move this into zustand - with lookupUser as a selector this
// won't
export function useLookupUsersWithAbstractAccountAddress() {
    const { accountAbstractionConfig } = useEnvironment()

    const userOpsInstance = useUserOps()
    const { memberIds } = useSpaceMembers()
    const setOfflineWalletAddress = useOfflineStore((s) => s.setOfflineWalletAddress)
    const { loggedInWalletAddress } = useConnectivity()

    const { lookupUser } = useUserLookupContext()

    return useQueries({
        queries: memberIds.map((userId) => {
            const cachedAddress = getCachedAddress({
                rootKeyAddress: userId,
                loggedInWalletAddress,
            })
            return {
                ...querySetup({
                    accountAbstractionConfig,
                    rootKeyAddress: userId as `0x${string}` | undefined,
                    userOpsInstance,
                    cachedAddress,
                    setOfflineWalletAddress,
                }),
            }
        }),
        combine: (results) => {
            return {
                data: results
                    .map((r, i): LookupUserWithAbstractAccountAddress => {
                        const user = lookupUser(memberIds[i])
                        return {
                            ...user,
                            abstractAccountAddress: r.data as `0x${string}`,
                        }
                    })
                    .filter(
                        (r): r is LookupUserWithAbstractAccountAddress =>
                            r.abstractAccountAddress !== undefined,
                    ),

                isLoading: results.some((r) => r.isLoading),
            }
        },
    })
}

export function isAbstractAccountAddress({
    address,
    abstractAccountAddress,
}: {
    address: Address
    abstractAccountAddress: Address | undefined
}) {
    return address.toLowerCase() === abstractAccountAddress?.toLowerCase()
}

function useUserOps() {
    const { clientSingleton } = useTownsContext()
    return clientSingleton?.baseTransactor.userOps
}

function useCachedAddress(rootKeyAddress: string | undefined) {
    const { loggedInWalletAddress } = useConnectivity()
    return getCachedAddress({ rootKeyAddress, loggedInWalletAddress })
}

// prevent infinite subscription updates to the offline wallet address map
let busted = false

function getCachedAddress(args: {
    rootKeyAddress: string | undefined
    loggedInWalletAddress: string | undefined
}) {
    const { rootKeyAddress, loggedInWalletAddress } = args
    /**
     * TODO: Remove mid feb 2025
     * This will bust the cache entry for the logged in wallet address.
     * Because we had a bug that set the root key address to a linked EOA address, when it should only point to the smart account address.
     *
     */
    if (loggedInWalletAddress === rootKeyAddress || !rootKeyAddress) {
        if (rootKeyAddress && !busted) {
            busted = true
            useOfflineStore.getState().removeOfflineWalletAddress(rootKeyAddress)
        }
        return
    }
    const offlineWalletAddressMap = useOfflineStore.getState().offlineWalletAddressMap
    return offlineWalletAddressMap[rootKeyAddress] as Address
}
